


















































































































































import { Component, Prop, Vue, Emit } from 'vue-property-decorator';
import { MembershipRequest } from '@/interfaces/membership-request';
import Approve from './Dialog/Approve.vue';
import SwitchBrocker from './Switch.vue';
import { membershipRequestModule } from '@/store/modules/membership-request';
import Reject from './Dialog/Reject.vue';

@Component({
    components: { SwitchBrocker, Approve, Reject }
})
export default class TableRequest extends Vue {
    @Prop({ required: true }) readonly tableData!: {
        data: MembershipRequest[];
        total: number;
    };

    dialog: {} = {
        row: undefined,
        step: 1,
        visible: false
    };

    secondDialog: {} = { row: undefined, step: 1, visible: false };

    async setStatus(row: MembershipRequest) {
        try {
            await membershipRequestModule.edit(row);
            await membershipRequestModule.index();
            this.dialog = Object.assign(this.dialog, { step: 2 });
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }

    async disaffiliate(row: MembershipRequest) {
        try {
            await membershipRequestModule.edit(row);
            await membershipRequestModule.index();
            this.secondDialog = Object.assign(this.secondDialog, { step: 2 });
        } catch (error) {
            this.$notify.error({
                title: 'Error',
                message:
                    typeof error.mensaje === 'string'
                        ? error.mensaje
                        : 'Hemos tenido algunos inconvenientes'
            });
        }
    }

    open(row: MembershipRequest) {
        this.secondDialog = {
            step: 1,
            visible: true,
            row: Object.assign(row, {
                fullName: `${row.from.nombres} ${row.from.apellidoPaterno} ${row.from.apellidoMaterno}`
            })
        };
    }

    changeStatus(row: MembershipRequest) {
        this.dialog = {
            step: 1,
            visible: true,
            row: Object.assign(row, {
                fullName: `${row.from.nombres} ${row.from.apellidoPaterno} ${row.from.apellidoMaterno}`
            })
        };
    }

    @Emit() changePage(data: number) {
        return data;
    }
}
