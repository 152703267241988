






















import { Component, Vue, Watch } from 'vue-property-decorator';
import { membershipRequestModule } from '@/store/modules/membership-request';
import FilterBroker from '@/components/Broker/Filter.vue';
import TableRequest from '@/components/Broker/TableRequest.vue';
import { Filter } from '@/interfaces/filter';

@Component({
    components: { FilterBroker, TableRequest }
})
export default class Request extends Vue {
    get tableData() {
        return membershipRequestModule.membershipRequests;
    }

    filterData(query: Filter) {
        membershipRequestModule.setFilter(query);
        membershipRequestModule.index();
    }

    changePage(page: number) {
        this.filterData({ page });
    }

    mounted() {
        membershipRequestModule.setFilter({
            page: parseInt(this.$route.query.page as string) || 1
        });
        membershipRequestModule.index();
    }
}
