









import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class SwitchBrocker extends Vue {
    @Prop({ required: true }) valueTrue!: number | boolean;
    @Prop({ type: Boolean, default: false }) disabled!: boolean;
    @Prop({ required: true }) readonly value!: number | boolean;

    get checked() {
        return this.value === this.valueTrue;
    }

    set checked(event: boolean) {
        // blocked
    }

    @Emit()
    click(event: boolean) {
        return event;
    }
}
