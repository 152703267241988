import { VuexModule, Module, Action } from 'vuex-class-modules';
import Axios from 'axios';
import store from '@/store';
import { MembershipRequest } from '@/interfaces/membership-request';
import { Filter } from '@/interfaces/filter';
import { Paginate } from '@/interfaces/paginate';

@Module({ generateMutationSetters: true })
export class MembershipRequestModule extends VuexModule {
    membershipRequests: Paginate<MembershipRequest> = {
        data: [],
        total: 0
    };

    filter: Filter = {
        page: 1
    };

    @Action
    async setFilter(filter: Filter) {
        this.filter = Object.assign(this.filter, filter);
    }

    @Action
    async index() {
        const { data } = await Axios.get<Paginate<MembershipRequest>>(
            'membership-request',
            { params: this.filter }
        );
        this.membershipRequests = data;
    }

    @Action
    async store(body: MembershipRequest) {
        await Axios.post('membership-request', body);
    }

    @Action
    async edit(body: MembershipRequest) {
        await Axios.put(`membership-request/${body._id}`, body);
    }

    @Action
    async send(body: Partial<MembershipRequest>) {
        const data = await Axios.post('membership-request', body);
        return data;
    }

    @Action
    async getMemberShipAccount() {
        const { data } = await Axios.get('membership-request/account');
        return data;
    }

    @Action
    async remove(body: Partial<MembershipRequest>) {
        const { data } = await Axios.delete(
            `membership-request/remove-membership/${body._id}`
        );
        return data;
    }
}
export const membershipRequestModule = new MembershipRequestModule({
    store,
    name: 'membershipRequest'
});
