























































import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class Reject<T> extends Vue {
    @Prop({ required: true }) row!: T;
    @Prop({ required: true, type: Number }) step!: string;

    submit() {
        this.$emit('submit', { ...this.row, status: 5 });
    }

    close() {
        this.$emit('close');
    }
}
